@import url('https://fonts.googleapis.com/css2?family=Asap:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;
}

html {
    scroll-behavior: smooth;
}

button {
    padding: 0.75rem 1.2rem;
    border-radius: 0.4rem;
    cursor: pointer;
    transition: all 0.25s ease-in-out;
}

img {
    display: block;
    object-fit: cover;
}

h1 {
    font-weight: 700;
    font-size: 72px;
    line-height: 83px;
}

h2 {
    font-weight: 400;
    font-size: 36px;
    line-height: 41px;
}

h3 {
    font-weight: 700;
    font-size: 36px;
    line-height: 41px;
}

h4 {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
}

p {
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
}

::-webkit-scrollbar {
    display: none;
}
/*
.btn-blue {
    display: inline-block;
    color: var(--color-primary);
    padding: 0.75rem 1.2rem;
    border-radius: 0.4rem;
    cursor: pointer;
    border: 1px solid var(--color-primary);
    transition: var(--transition);
}

.btn:hover {
    background: var(--color-white);
    color: var(--color-bg);
    border-color: transparent;
}

.btn-primary {
    background: var(--color-primary);
    color: var(--color-bg);
}

img {
    display: block;
    width: 100%;
    object-fit: cover;
}*/

/* ============= MEDIA QUERIES (MEDIUM DEVICES) =============== */
/*@media screen and (max-width: 1024px) {
    .container {
        width: var(--container-width-md);
    }

    section {
        margin-top: 6rem;
    }
}

/* ============= MEDIA QUERIES (SMALL DEVICES) =============== */
/*@media screen and (max-width: 600px) {
    .container {
        width: var(--container-width-sm);
    }

    section > h2 {
        margin-top: 2rem;
    }
}*/