.slider:before{
    background-image: url("../../assets/moon_toggle.png");
    background-size: contain;
}

input:checked + .slider {
    background-color: transparent !important;
    border-color: #F3E27C;
}

input:checked + .slider:before {
    -webkit-transform: translateX(20px) rotate(90deg);
    -ms-transform: translateX(20px) rotate(90deg);
    transform: translateX(20px) rotate(90deg);
    background-image: url("../../assets/sun_toggle.png");
    background-size: contain;
}